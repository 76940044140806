/* eslint-disable react/display-name */
import React from 'react'
import { Text, TEXT } from './text'
import { Number, NUMBER } from './number'
import { TrueFalse, TRUEFALSE } from './trueFalse'
import { Date, DATE } from './date'
import { Selection, SELECTION } from './selection'
import { FILE, File } from './file'
import { NestedObject, NESTEDOBJECT } from './nestedObject'
import { Ace, Input, Select } from '../../../../components'
import { OBJECT, GLOBAL } from '../../../../config'
import { InferLabelFromName } from '../../../../utils'
import classNames from 'classnames'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  editMemberProperty,
  editMemberPropertyByPath,
  TypesSelector,
} from '../../state'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'
import ActionsList from '../../../../components/ActionsList'
import Notes from '../../notes'

const Types = () => {
  const setProperty = useSetRecoilState(editMemberPropertyByPath)
  const types = useRecoilValue(TypesSelector)

  const unlabeled = useRecoilValue(editMemberProperty('unlabeled'))
  const type = useRecoilValue(editMemberProperty('type'))
  const name = useRecoilValue(editMemberProperty('name'))
  const wasChanged = useRecoilValue(editMemberProperty('wasChanged'))
  const isExternal = useRecoilValue(editMemberProperty('isExternal'))
  const isList = useRecoilValue(editMemberProperty('isList'))
  const typeName = useRecoilValue(editMemberProperty('typeName'))

  const objects = types.map(type => type.name)
  const typeOptions = !isList
    ? [TEXT, NUMBER, DATE, TRUEFALSE, SELECTION, FILE, 'line', ...objects]
    : [TEXT, SELECTION, FILE, 'line', ...objects]
  if (typeName && !objects.includes(typeName)) {
    typeOptions.splice(typeOptions.length, 0, 'line', typeName)
  }

  const valueObject = () => {
    if (typeName) {
      return typeName
    } else {
      return 'error'
    }
  }

  const onChange = (key, value) => {
    setProperty({
      path: key,
      value,
    })
  }

  return (<div className={'w-100'}>
    <div className={'row'}>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'name'} defaultValue={''}>
          {({
            value,
            onChange,
          }) => (
            <Input label={'VARIABLE NAME'} value={value}
                   onChange={val => {
                     onChange(val)
                     setProperty({
                       path: 'wasChanged',
                       value: true,
                     })
                   }}
                   id={'propertyName'}
                   disabled={isExternal}
                   type={'text'}
                   spellcheck={false}/>)}</EditMemberPropertyComponent>
      </div>
      <div className={'col-6'}>
        <label>TYPE</label>
        <div className={'d-flex '}>
          <EditMemberPropertyComponent path={'isList'}>
            {({
              value,
              onChange,
            }) => (
              <div className={'w-25 d-flex flex-column'} style={{
                height: 38,
                marginTop: -3,
              }}>
                <div className="form-check">
                  <input className="form-check-input" type="radio" id={'single'}
                         name={'formula'}
                         checked={!value}
                         disabled={isExternal}
                         onChange={() => {
                           onChange(false)
                           if (type === SELECTION) {
                             setProperty({
                               path: 'style',
                               value: 'radiobuttons',
                             })
                           }
                           setProperty({
                             path: 'wasChanged',
                             value: true,
                           })
                         }}
                  />
                  <label className="form-check-label" htmlFor={'single'}>
                    Single
                  </label>
                </div>
                <div className="form-check" style={{ marginTop: -1 }}>
                  <input className="form-check-input" type="radio" id={'ListOf'}
                         name={'formula'}
                         checked={value}
                         disabled={isExternal}
                         onChange={() => {
                           onChange(true)
                           if (type === SELECTION) {
                             setProperty({
                               path: 'style',
                               value: 'checkboxes',
                             })
                           }
                           setProperty({
                             path: 'wasChanged',
                             value: true,
                           })
                         }}
                  />
                  <label className="form-check-label" htmlFor={'ListOf'}>
                    List of
                  </label>
                </div>
              </div>
            )}
          </EditMemberPropertyComponent>
          <div className={'w-50 d-flex'}>
            <Select values={typeOptions}
                    value={type === OBJECT || type === GLOBAL
                      ? valueObject()
                      : type}
                    disabled={isExternal || type === GLOBAL}
                    className={'form-group'}
                    onChange={val => {
                      if (objects.includes(val)) {
                        const t = types.find(t => t.name === val)
                        onChange('type', OBJECT)
                        onChange('typeName', val)
                        onChange('typeDef', t._id)
                        onChange('style', 'popup')
                        onChange('wasChanged', true)
                      } else {
                        onChange('type', val)
                        onChange('wasChanged', true)
                        if (val === NUMBER) {
                          onChange('style', 'textbox')
                        }
                        if (val === DATE) {
                          onChange('style', 'datepicker')
                        }
                        if (val === TRUEFALSE) {
                          onChange('style', 'checkbox')
                        }
                        if (val === SELECTION) {
                          onChange('style', 'radiobuttons')
                        }
                        if (val === FILE) {
                          onChange('style', 'upload')
                        }
                        if (val === TEXT) {
                          onChange('style', 'textbox')
                          onChange('height', 1)
                        }
                      }
                    }}
            />
          </div>
          {!isExternal && (
            <div className={'ml-4 mb-1'} style={{ flexGrow: 0 }}>
              <ActionsList/>
            </div>
          )}
        </div>
      </div>
      {wasChanged && (
        <div className={'col-12'}><p
          className={'text-danger mb-2, mt-0'}>Changes to a variable&apos;s name
          or type
          can cause data loss because the changes are not applied to existing
          records.</p>
        </div>)}
      <div className={'col-6 form-group'}>
        <div className={'row'}>
          <div className={classNames('col-6',
            { 'opacity-3': !!unlabeled })}>
            <label>QUESTION PROMPT</label>
          </div>
          {(type !== NESTEDOBJECT && type !== FILE) && (
            <div className={'col'}>
              <div className="form-group form-check m-0">
                <EditMemberPropertyComponent path={'unlabeled'}>
                  {({
                    value,
                    onChange,
                  }) => (
                    <input type="checkbox"
                           className="form-check-input"
                           id="LeaveBlank"
                           checked={!!value}
                           onChange={() => onChange(!value)}/>
                  )}</EditMemberPropertyComponent>
                <label className="form-check-label"
                       htmlFor="LeaveBlank">Unlabeled</label>
              </div>
            </div>
          )}
        </div>
        <div className={classNames(
          'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper',
          { 'opacity-3': !!unlabeled })}>
          <EditMemberPropertyComponent path={'label'} defaultValue={''}>
            {({
              value,
              onChange,
            }) => (
              <Ace
                name={'label'}
                placeholder={InferLabelFromName(name)}
                className={'border-0'}
                value={!!unlabeled === true ? '' : value || ''}
                disabled={!!unlabeled}
                onChange={val => onChange(!!unlabeled === true ? '' : val)}
                maxLines={Infinity}/>)}
          </EditMemberPropertyComponent>
        </div>
      </div>
      <div className={'col-6'}>
        <label>RELEVANCE (CONDITION)</label>
        <div
          className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>

          <EditMemberPropertyComponent path={'forceRelevance'}
                                       defaultValue={''}>
            {({
              value,
              onChange,
            }) => (
              <Ace
                name={'forceRelevance'}
                id={'relevance'}
                placeholder={'automatic'}
                className={'border-0'}
                value={value || ''}
                onChange={onChange}
                maxLines={Infinity}
                mode={'kexpr'}
                isExpression={true}
              />)
            }</EditMemberPropertyComponent>
        </div>
      </div>
      <div
        className={classNames('col-12', { 'opacity-3': !!unlabeled })}>
        <label>QUESTION HELP</label>
        <div
          className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>

          <EditMemberPropertyComponent path={'helpText'} defaultValue={''}>
            {({
              value,
              onChange,
            }) => (<Ace
              name={'helpText'}
              placeholder={''}
              className={'border-0'}
              value={!!unlabeled === true ? '' : value || ''}
              onChange={val => onChange(!!unlabeled === true ? '' : val)}
              maxLines={Infinity}/>)}
          </EditMemberPropertyComponent>
        </div>
      </div>
      {type === TEXT && (<Text/>)}
      {type === NUMBER && (<Number/>)}
      {type === TRUEFALSE && (<TrueFalse/>)}
      {type === DATE && (<Date/>)}
      {type === SELECTION && (<Selection/>)}
      {type === NESTEDOBJECT && (<NestedObject/>)}
      {type === GLOBAL && (<NestedObject/>)}
      {type === FILE && (<File/>)}
      <Notes/>
    </div>
  </div>)
}

export default Types
