const getIsProduct = (el) => {
  if(!el){
    return false
  }
  if (window.knacklyAddons.dev) {
    return false
  }
  return !!el.isProduct
}

export default getIsProduct
