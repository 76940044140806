import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { OBJECT, PDFF } from '../../config'
import { Icon } from '../index'
import { useHistory } from 'react-router-dom'

const StyledMemberCard = styled.li`
    background-color: ${props => props.theme.colorTable.plain} !important;
    border-color: ${props => props.theme.colorTable.plain} !important;
    color: ${props => props.theme.colorTable.text} !important;

    &.active {
        background-color: ${props => props.theme.colorTable.listSelection} !important;
        border-color: ${props => props.theme.colorTable.listSelection} !important;
        color: ${props => props.theme.colorTable.listSelectionText} !important;
    }

    &:hover:not(.active) {
        background: ${(props) => props.theme.colorTable.backLightHover} !important;
        border-color: ${(props) => props.theme.colorTable.backLightHover} !important;
    }
`

const MemberCard = ({
  member,
  isActive,
  changeActive,
  typeKey,
  getIconType,
}) => {
  const history = useHistory()

  let labelType = `${member.type === OBJECT && member.typeName
    ? member.typeName
    : member.type === PDFF
      ? 'pdf' : member.type || ''} ${member.isList ? ' list' : ''}`

  const nodeType = (<div className={classNames('ml-2 text-truncate',
    { 'link-knackly': !!member.typeName })}
                         onClick={(event) => {
                           event.preventDefault()
                           event.stopPropagation()
                           if (member.typeName) {
                             history.push(
                               `/${window.tenancy}/types/${member.typeName}/`)
                           }
                         }}>{labelType}</div>)

  return (
    <StyledMemberCard
      className={classNames(
        'list-group-item rounded py-1 px-3 my-1 cursorPointer prevent-select',
        { 'active': isActive })}
      onClick={(event) => {
        const e = event || window.event
        if (e.metaKey || e.ctrlKey) {
          return changeActive('select', member)
        }
        if (e.shiftKey) {
          return changeActive('shift', member)
        }
        return changeActive('click', member)
      }}>
      <div className="d-flex">
        <div className={'mr-auto flex-grow-1 text-nowrap'} style={{
          maxWidth: '95%',
          textOverflow: 'ellipsis',
          overflow: 'unset',
        }}>
          {(typeKey === member.name && (
            <Icon type={'icon-key1'}
                  className={'align-middle'} style={{ marginTop: -3 }}/>
          )) || (member.type === 'global' && (
            <Icon type={'icon-global'}
                  className={'align-middle'}
                  style={{ marginTop: -3, marginLeft: -14 }}/>
          ))}
          <Icon
            type={getIconType(member.type)}
            className={'align-middle '}
            style={{ marginTop: -3 }}/> {member.name.length > 22
          ? member.name.slice(0, 21) + '...'
          : member.name}
        </div>
        {nodeType}
      </div>
    </StyledMemberCard>)
}

export default MemberCard
